import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

const IndexPage = () => {
    return (
        <Layout>
            <Head title="Home" />
            <h1>Hello.</h1>
            <h2>Welcome to a China finance research blog.</h2>
            <p>This blog was started in 2015 by Luke Deer.</p>
            <p>It's in maintainence and will be back soon...</p>
            <p>You can read the old posts above.</p>
        </Layout>
    )
}

export default IndexPage